import jwtDecode from 'jwt-decode';
import {REACT_APP_API_TOKEN} from "../config";

let _timeoutId: any;
const _TEN_SECONDS_IN_MS = 10000;

const TokenService = {
    saveAuthToken(token: any) {
        if (!REACT_APP_API_TOKEN) {
            throw new Error('Missing REACT_APP_API_TOKEN while saving auth token');
        }

        window.localStorage.setItem(REACT_APP_API_TOKEN, token);
        console.log('☑️ set auth token');
    },

    saveUserId(userId: number | string) {
        if (typeof userId === "string") {
            window.localStorage.setItem('userId', userId);
            console.log('☑️ set userId');
        } else {
            window.localStorage.setItem('userId', String(userId));
            console.log('☑️ set userId');
        }
    },

    getAuthToken() {
        if (!REACT_APP_API_TOKEN) {
            throw new Error('Missing REACT_APP_API_TOKEN while getting auth token');
        }

        return window.localStorage.getItem(REACT_APP_API_TOKEN);
    },

    clearAuthToken() {
        if (!REACT_APP_API_TOKEN) {
            throw new Error('Missing REACT_APP_API_TOKEN while clearing token');
        }

        window.localStorage.removeItem(REACT_APP_API_TOKEN);
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('firstName');
        window.localStorage.removeItem('lastName');
        window.localStorage.removeItem('driverId');
        window.localStorage.removeItem('dispatcherId');
        window.localStorage.removeItem('adminId');
        console.log('☑️ cleared auth token');
    },

    hasAuthToken() {
        return !!TokenService.getAuthToken();
    },

    parseJwt(jwt: any) {
        return jwtDecode(jwt);
    },

    readJwtToken() {
        return TokenService.parseJwt(TokenService.getAuthToken());
    },

    _getMsUntilExpiry(payload: any) {
        /*
          payload is from the JWT
          the `exp` value is in seconds, need to convert to ms, so * 1000
          calculates the difference between now and when the JWT will expire
        */
        return payload.exp * 1000 - Date.now();
    },

    queueCallbackBeforeExpiry(callback: any) {
        /* get the number of ms from now until the token expires */
        const msUntilExpiry = TokenService._getMsUntilExpiry(
            TokenService.readJwtToken(),
        );
        /*
          queue a callback that will happen 10 seconds before the token expires
          the callback is passed in as an argument so could be anything,
            in this app, the callback is for calling the refresh endpoint
        */
        _timeoutId = setTimeout(callback, msUntilExpiry - _TEN_SECONDS_IN_MS);
    },

    clearCallbackBeforeExpiry() {
        clearTimeout(_timeoutId);
    },
};

export default TokenService;
