import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import './App.css';

// services
import TokenService from './helpers/token-service';

const DispatcherPage = lazy(() => import('./DispatcherPage/DispatcherPage'));
const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
const SchedulePage = lazy(() => import('./SchedulePage/SchedulePage'));
const Dash = lazy(() => import('./Dash'));
const TicketsPage = lazy(() => import('./TicketsPage/TicketsPage'));
const TrucksPage = lazy(() => import('./TrucksPage/TrucksPage'));
const AdminPage = lazy(() => import('./AdminPage/AdminPage'));
const CompanyPage = lazy(() => import('./CompanyPage/CompanyPage'));
const TruckSelectPage = lazy(() => import('./TruckSelectPage'));
const SuperAdminPage = lazy(() => import('./SuperAdminPage/SuperAdminPage'));
const Dashboard = lazy(() => import('./Dashboard'));
const SignInPage = lazy(() => import('./SignInPage'));
const ErrorPage = lazy(() => import('./ErrorPage/ErrorPage'));
const NotFoundPage = lazy(() => import('./NotFound/NotFoundPage'));

function App() {
    const authToken = TokenService.getAuthToken();

    let storedDriverId = localStorage.getItem('driverId');
    let storedDispatcherId = localStorage.getItem('dispatcherId');
    let storedAdminId = localStorage.getItem('adminId');
    let storedSuperAdminId = localStorage.getItem('superadminId');

    const ProtectedRoute = ({isAllowed, children}: any) => {
        if (!isAllowed) {
            return <Navigate to="/" replace />;
        }

        return children ? children : <Outlet />;
    };

    return (
        <Routes>
            <Route
                path={"/t/:token"}
                element={
                    <Suspense fallback={<div>Loading...</div>}>
                        <TruckSelectPage/>
                    </Suspense>
                }
                errorElement={
                    <Suspense fallback={<div>Loading...</div>}>
                        <ErrorPage/>
                    </Suspense>
                }
            />
            <Route path="/" element={
                <Suspense fallback={<div>Loading...</div>}>
                    {authToken ? <Dashboard /> : <LoginPage />}
                </Suspense>
            } />
            <Route path="/sign-in" element={
                <Suspense fallback={<div>Loading...</div>}>
                    <SignInPage />
                </Suspense>
            } />
            <Route element={<ProtectedRoute isAllowed={authToken} />}>
                <Route
                    path="/dispatcher"
                    element={
                        <ProtectedRoute isAllowed={authToken && storedDispatcherId}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <DispatcherPage />
                            </Suspense>
                        </ProtectedRoute>
                    }
                />
                <Route path="/schedule" element={<SchedulePage />} />
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute isAllowed={storedAdminId}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <AdminPage />
                            </Suspense>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={"superadmin"}
                    element={
                        <ProtectedRoute isAllowed={storedSuperAdminId}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <SuperAdminPage/>
                            </Suspense>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/company"
                    element={
                        <ProtectedRoute isAllowed={storedAdminId}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <CompanyPage/>
                            </Suspense>
                        </ProtectedRoute>
                    }
                />
                <Route path="/dash" element={
                    <Suspense fallback={<div>Loading...</div>}>
                        <Dash />
                    </Suspense>
                } />
                <Route path="/tickets" element={
                    <Suspense fallback={<div>Loading...</div>}>
                        <TicketsPage />
                    </Suspense>
                } />
                <Route path="/trucks" element={
                    <Suspense fallback={<div>Loading...</div>}>
                        <TrucksPage />
                    </Suspense>
                } />
            </Route>
            <Route path="*" element={
                <Suspense fallback={<div>Loading...</div>}>
                    <NotFoundPage />
                </Suspense>
            } />
        </Routes>
    );
}

export default App;
